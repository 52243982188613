import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const BiographyShort = () => {
  const data = useStaticQuery(graphql`
    query BioShort {
      file(absolutePath: { regex: "/Allie-2021-laugh.jpeg/" }) {
        childImageSharp {
          fixed(width: 300, height: 500, quality: 80, cropFocus: ATTENTION) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <div id="biography-short" className="[ section wrapper ]">
        <div className=" [ accent-line ] ">
          <Link to="/biography/">
            <h2>Biography</h2>
          </Link>
        </div>
        <div className="shell">
          <div className="img [ radius bg-black ]">
            <Img
              fixed={{ ...data.file.childImageSharp.fixed }}
              className="[ radius ]"
            />
          </div>
          <div id="bio-content" className="[ flow ]">
            <p>
              Soprano Alexandra Whitfield, praised for her “touching and
              poignant” sound (Boston Musical Intelligencer), is an active
              performer in oratorio, opera, concert
              and new music. Her most recent engagements include The Queen in
              Solomon with Cantata Singers, Pamina in The Magic Flute with The
              Nahant Music Festival, Handel’s Messiah with Masterworks Chorale
              and the Fauré Requiem with The Litchfield County Choral Union.
            </p>

            <p>
              As a dedicated promoter of chamber music and new compositions,
              Alexandra recently produced a world premiere program, "
              <a
                href="https://mlkmemorialconcert.wordpress.com/"
                className="[ link-hover color-secondary ]"
              >
                In His Own Words
              </a>
              ,” to honor Dr. Martin Luther King’s 50th anniversary.
            </p>

            <p>
              Alexandra is a member of Ars Nova, Cantata Singers, et. al. and is a
              founding member of the chamber group{" "}
              <a
                href="https://tetratrio.wordpress.com/"
                className="[ link-hover color-secondary ]"
              >
                Tetra Trio
              </a>
              .
            </p>
            <div className="to-somewhere">
              <Link to="/biography/" className="[ link-hover ]">
                Click for full Bio
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BiographyShort
