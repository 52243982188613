import React from "react"
import { Link } from "gatsby"

const Header = () => {
  return (
    <>
      <div id="header">
        <div className="wrapper">
          <nav className="content [ splitter ]">
            <div>
              <Link to="/">
                  {/* <div id="left" /> */}
                <h1>
                  Alexandra Whitfield
                </h1>
                  {/* <div id="right" /> */}
              </Link>
            </div>
            <div className="nav-links">
              <Link to="/listen/" className="nav-item [ link-hover ] ">
                Listen
              </Link>
              <Link to="/performances/" className="nav-item [ link-hover ] ">
                Performances
              </Link>
              <Link to="/biography/" className="nav-item bio [ link-hover ] ">
                Biography
              </Link>
              <Link to="/contact/" className="nav-item [ link-hover ] ">
                Contact
              </Link>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Header
