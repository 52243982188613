import React from "react"
import Chimp from "./Chimp"

const Subscribe = () => {
  return (
    <>
      <div id="contact" className=" [ section wrapper ] ">
        <div className="accent-line">
          <h2>Subscribe</h2>
        </div>
        <div className="form">
          <p>
            If you would like to stay up to date on my upcoming performances and
            other updates, sign up for my mailing list.
          </p>
          <Chimp />
        </div>
      </div>
    </>
  )
}

export default Subscribe
