import React from "react"
import Chimp from "./Chimp"
import { Link } from "gatsby"
import {
  RiFacebookBoxFill,
  RiInstagramLine,
  RiYoutubeFill,
} from "react-icons/ri"

const Footer = () => {
  return (
    <>
      <div id="footer">
        <div className="decoration">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 35">
            <path id="a" d="M 0,23 C 163,23 200,3 200,3 V 33 H 0 Z" />
            <path id="b" d="M 0,23 C 163,23 200,10 200,10 V 35 H 0 Z" />
          </svg>
        </div>
        <div className="[ bg-tertiary ]">
          <div className="content [ wrapper color-white ]">
            <div className="columns">
              <div className="col">
                <div className="head">Social Media</div>
                <div>
                  <a
                    href="https://www.youtube.com/channel/UC5uNtADOdQ9JuwkjSbM2Ahg"
                    className="[ link-hover ]"
                  >
                    <RiYoutubeFill className="icons" /> Youtube
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.facebook.com/alexandrawhitfieldb/"
                    className="[ link-hover ]"
                  >
                    <RiFacebookBoxFill className="icons" /> facebook
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.instagram.com/abw33/?hl=en"
                    className="[ link-hover ]"
                  >
                    <RiInstagramLine className="icons" /> instagram
                  </a>
                </div>
              </div>
              <div className="col">
                <div className="head">Site Navigation</div>
                <div>
                  <Link to="/" className="[ link-hover ]">
                    Home
                  </Link>
                </div>
                <div>
                  <Link to="/listen/" className="[ link-hover ]">
                    Listen
                  </Link>
                </div>
                <div>
                  <Link to="/performances/" className="[ link-hover ]">
                    Performances
                  </Link>
                </div>
                <div>
                  <Link to="/biography" className="[ link-hover ]">
                    Biography
                  </Link>
                </div>
                <div>
                  <Link to="/contact/" className="[ link-hover ]">
                    Contact
                  </Link>
                </div>
              </div>
              <div className="col subscribe">
                <div className="head">Mailing List Signup</div>
                <Chimp />
              </div>
            </div>
            <div>&#169; Alexandra Whitfield {new Date().getFullYear()}</div>
            <div style={{margin: `.5rem 0`}}>Headshots by Charis Loh</div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Footer
