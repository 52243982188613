import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import AudioCard from "./AudioCard"

const Audio = () => {
  const data = useStaticQuery(graphql`
    query Audio {
      allFile(
        filter: {
          childMarkdownRemark: { frontmatter: { post_type: { eq: "audio" } } }
        }
      ) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                title
                composer
                accompanist1
                accompanist2
                accompanist3
                attachment {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)
  const song = data.allFile.edges
  return (
    <>
      <div className=" listen [ wrapper section ]">
        <div className=" [ accent-line ] ">
          <h2>Audio</h2>
        </div>
        <div className="flex-wrapper">
          {song.map(({ node }) => {
            return (
              <AudioCard
                key={node.id}
                title={node.childMarkdownRemark.frontmatter.title}
                composer={node.childMarkdownRemark.frontmatter.composer}
                accompanist1={node.childMarkdownRemark.frontmatter.accompanist1}
                accompanist2={node.childMarkdownRemark.frontmatter.accompanist2}
                accompanist3={node.childMarkdownRemark.frontmatter.accompanist3}
                audioSrc={
                  node.childMarkdownRemark.frontmatter.attachment.publicURL
                }
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Audio
