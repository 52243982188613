import React from "react"
import EventCard from "./EventCard"
import { Link, graphql, useStaticQuery } from "gatsby"

const PerformancePreview = () => {
  const data = useStaticQuery(graphql`
    query performancePreview {
      allFile(
        filter: {
          childMarkdownRemark: {
            frontmatter: { post_type: { eq: "performance" } }
          }
        }
      ) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                performance_name
                company
                date(formatString: "YYYY-MM-DD")
                displaydate: date(formatString: "D MMMM YYYY")
                year: date(formatString: "YYYY")
                month: date(formatString: "MMM")
                day: date(formatString: "D")
                time
                description
                ticket_link
                preview_image {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              fields {
                slug
              }
              excerpt
            }
          }
        }
      }
    }
  `)

  function getCurrentDate() {
    const d = new Date()
    let month = (d.getMonth() + 1).toString()
    if (month.length < 2) {
      month = `0${month}`
    }
    let day = d.getDate().toString()
    if (day.length < 2) {
      day = `0${day}`
    }
    return `${d.getFullYear()}-${month}-${day}`
  }
  const unsortedevents = data.allFile.edges

  const events = unsortedevents.sort(function (a, b) {
    let nameA = a.node.childMarkdownRemark.frontmatter.date
    let nameB = b.node.childMarkdownRemark.frontmatter.date
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    // names must be equal
    return 0
  })

  const futureEvents = events.filter(compareDate)
  const event = futureEvents[0] ? futureEvents[0].node : undefined
  function compareDate(date) {
    return date.node.childMarkdownRemark.frontmatter.date >= getCurrentDate()
  }

  return (
    <>
      {event && (
        <div id="performance-preview" className=" [ wrapper section ] ">
          <div className="[ accent-line-two-rows ]">
            <Link to="/performances/">
              <h2>Upcoming Performance</h2>
            </Link>
          </div>
          <EventCard
            date={event.childMarkdownRemark.frontmatter.displaydate}
            day={event.childMarkdownRemark.frontmatter.day}
            month={event.childMarkdownRemark.frontmatter.month}
            year={event.childMarkdownRemark.frontmatter.year}
            slug={event.childMarkdownRemark.fields.slug}
            performance_name={
              event.childMarkdownRemark.frontmatter.performance_name
            }
            company={event.childMarkdownRemark.frontmatter.company}
            description={event.childMarkdownRemark.frontmatter.description}
            excerpt={event.childMarkdownRemark.excerpt}
            ticket_link={event.childMarkdownRemark.frontmatter.ticket_link}
            time={event.childMarkdownRemark.frontmatter.time}
            preview_image={
              event.childMarkdownRemark.frontmatter.preview_image
                .childImageSharp.fluid
            }
            more_details={true}
            utility_class="hover-shadow"
          />
          <div style={{ textAlign: `right` }}>
            <div className="to-somewhere">
              <Link to="/performances/" className="[ link-hover ]">
                More Performances
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PerformancePreview
