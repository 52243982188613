import React from "react"

const VideoCard = props => {
  return (
    <>
      <div className="card">
        <div className="video">
          {props.youtube_link && (
            <iframe
              title="a"
              type="text/html"
              controls="0"
              src={props.youtube_link}
              frameBorder="0"
              allowFullScreen="1"
            />
          )}
          {props.local_video_URL && (
            <video controls>
              <source src={props.local_video_URL} type="video/mp4" />
            </video>
          )}
        </div>
        <div className="title color-secondary">
          {/* <div className="left" /> */}
          {props.title}
          {/* <div className="right" /> */}
        </div>
        <div className="composer">by {props.composer}</div>
        <div className="details">
          <div>{props.full_work}</div>
          {props.performer1 && <div>{props.performer1}</div>}
          {props.performer2 && <div>{props.performer2}</div>}
          {props.performer3 && <div>{props.performer3}</div>}
          {props.date}
          <br />
        </div>
      </div>
    </>
  )
}

export default VideoCard
