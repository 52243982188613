import React from "react"
import Img from "gatsby-image"

const Intro = props => {
  return (
    <>
      <div id="intro" className="[ section wrapper-no-margin ]">
        <div>
          <div className={`name [ ${props.bg} color-white ]`}>
            <div>
              <h2>Alexandra Whitfield</h2>
              <h3>Soprano</h3>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 100">
              <path id="profile-crop" d="m25 100h-25v-100h5z" className={props.svgFill} />
            </svg>
          </div>
          <div className="img">
            <Img fluid={props.picture} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Intro
