import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { GoLinkExternal } from "react-icons/go"

const EventCard = props => {
  return (
    <>
      <div className={`event-card [ radius ${props.utility_class} ] `}>
        {props.preview_image && (
          <div className="image radius ">
            <Img fluid={props.preview_image} />
          </div>
        )}
        <div className="description [ flow ] ">
          <div className="details">
            <div className="[ flow ]">
              <div>
                <h3>
                  <Link className=" [ link-hover color-secondary ] " to={props.slug}>
                    {props.performance_name}
                  </Link>
                </h3>
                <div className="company [ color-tertiary ]">
                  {props.company}
                </div>
              </div>
              {props.year !== "2100" && (
                <div className="time">
                  {props.date}
                  <br />
                  {props.time}
                </div>
              )}
              {props.year === "2100" && (
                <div className="time">Date and Time: TBD</div>
              )}
            </div>
            {props.ticket_link && (
              <div>
                <a href={props.ticket_link} className="tickets [ radius ]">
                  <h3>
                    Tickets <GoLinkExternal className="icons" />
                  </h3>
                </a>
              </div>
            )}
          </div>
          {props.description && <div>{props.description}</div>}
          {props.more_details && (
            <div className="to-somewhere">
              <Link to={props.slug} className="[ link-hover ]">
                More details
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default EventCard
