import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Avatar = () => {
  const AvatarImage = useStaticQuery(graphql`
    query AvatarImage {
      file(absolutePath: { regex: "/allie-headshot-1-square.jpg/" }) {
        childImageSharp {
          fixed(quality: 80, width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <div className="avatar">
      <Link to="/biography/"> <Img fixed={AvatarImage.file.childImageSharp.fixed} /></Link>
    </div>
  )
}

export default Avatar
