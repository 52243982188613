import React from "react"
import { Link } from "gatsby"
import Avatar from "./Avatar"

const Contact = () => {
  return (
    <>
      <div id="contact" className=" [ section wrapper ] ">
        <div className="accent-line">
          <h2>Contact</h2>
        </div>
        <div className="hi">
          <div className="text [ flow ]">
            <p>
              If you have a performance opportunity, would like to collaborate, or have any questions, send me a
              message and I will get back to you as soon as I can.
            </p>
			<div className="back-to-somewhere">
				<Link to="/" className="[ link-hover ]" > Back to home </Link>
			</div>
          </div>
          <Avatar />
        </div>
        <div className="form">
          <form name="contact" className=" [ flow ] " method="post" action="/thankyou/" data-netlify="true">
              <input type="hidden" name="form-name" value="contact" />
            <label>
              <input type="text" name="name" id="name" placeholder="Name" />
            </label>
            <label>
              <input type="email" name="email" id="email" placeholder="Email" />
            </label>
            <label>
              <input
                type="text"
                name="subject"
                id="subject"
                placeholder="Subject"
              />
            </label>
            <label>
              <textarea
                name="message"
                id="message"
                rows="5"
                placeholder="Message"
              />
            </label>
            <div style={{ textAlign: `center` }}>
              <button
                type="submit"
              >
                <h1>Send</h1>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Contact
