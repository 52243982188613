import React from "react"

const AudioCard = props => {
  return (
    <>
      <div className="card ">
        <div className="audio-description">
          <div className="title color-secondary">
            {/* <div className="left" /> */}
            {props.title}
            {/* <div className="right" /> */}
          </div>
          {props.composer && (
            <div className="composer">by {props.composer}</div>
          )}
          <div className="details">
            {props.accompanist1 && (
              <div>{props.accompanist1}</div>
            )}
            {props.accompanist2 && (
              <div>{props.accompanist2}</div>
            )}
            {props.accompanist3 && (
              <div>{props.accompanist3}</div>
            )}
          </div>
        </div>

        <audio controls controlsList="nodownload">
          <source src={props.audioSrc} type="audio/mpeg" />
        </audio>
      </div>
    </>
  )
}

export default AudioCard
