import React from "react"
import Mailchimp from "react-mailchimp-form"

const Chimp = () => {
  return (
    <Mailchimp
    //   action="https://dev.us17.list-manage.com/subscribe/post?u=ea8099d8d6a97942b1e8e6044&amp;id=eb49ddb36a"
      action="https://alexandrawhitfield.us10.list-manage.com/subscribe/post?u=14288389ab58102f62bdd9f32&amp;id=31ed52bd3f"
      fields={[
        {
          name: "FNAME",
          placeholder: "Name",
          type: "text",
          required: true,
        },
        {
          name: "EMAIL",
          placeholder: "Email",
          type: "email",
          required: true,
        },
      ]}
      messages={{
        sending: "Sending...",
        success: "Thank you for subscribing!",
        error: "An error has occurred.",
        empty: "You must write an e-mail.",
        duplicate: "Too many subscribe attempts for this email address",
        button: "Subscribe",
      }}
      className="mailchimp"
    />
  )
}


export default Chimp