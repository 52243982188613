import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import "../css/global.css"

const Layout = ({ children }) => {
  return (
    <div>
      <main>
        <Header />
        {children}
      </main>
	  <Footer />
    </div>
  )
}

export default Layout
