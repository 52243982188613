import React from "react"

const PastEventCard = props => {
  return (
    <>
      <div className={`event-card [ radius ${props.utility_class} ] `}>
        <div className="description [ flow ] ">
          <div className="details [  ] ">
            <div className="[ flow ]">
              <div>
                <h4 className="[ color-secondary ]">
                    {props.performance_name}
                </h4>
                <h5>{props.company}</h5>
                <h5>{props.date}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PastEventCard
