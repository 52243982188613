import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import VideoCard from "./VideoCard"

const Video = () => {
  const video = useStaticQuery(graphql`
    query video {
      you_tube: allFile(
        filter: {
          childMarkdownRemark: { frontmatter: { post_type: { eq: "youtube" } } }
        }
      ) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                title
                composer
                full_work
                performer1
                performer2
                performer3
                youtube_link
                date(formatString: "D MMMM YYYY")
              }
            }
          }
        }
      }
      local_video: allFile(
        filter: {
          childMarkdownRemark: {
            frontmatter: { post_type: { eq: "local_video" } }
          }
        }
      ) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                title
                composer
                full_work
                performer1
                performer2
                performer3
                date(formatString: "D MMMM YYYY")
                attachment {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  const youTube = video.you_tube.edges
  const localVideo = video.local_video.edges

  return (
    <>
      <div className=" listen [ wrapper section ]">
        <div className=" [ accent-line ] ">
          <h2>Video</h2>
        </div>
        <div className="flex-wrapper">
          {youTube.map(({ node }) => {
            return (
              <VideoCard
                key={node.id}
                title={node.childMarkdownRemark.frontmatter.title}
                composer={node.childMarkdownRemark.frontmatter.composer}
                full_work={node.childMarkdownRemark.frontmatter.full_work}
                performer1={node.childMarkdownRemark.frontmatter.performer1}
                performer2={node.childMarkdownRemark.frontmatter.performer2}
                performer3={node.childMarkdownRemark.frontmatter.performer3}
                youtube_link={node.childMarkdownRemark.frontmatter.youtube_link}
                date={node.childMarkdownRemark.frontmatter.date}
              />
            )
          })}
          {localVideo.map(({ node }) => {
            return (
              <VideoCard
                key={node.id}
                title={node.childMarkdownRemark.frontmatter.title}
                composer={node.childMarkdownRemark.frontmatter.composer}
                full_work={node.childMarkdownRemark.frontmatter.full_work}
                performer1={node.childMarkdownRemark.frontmatter.performer1}
                performer2={node.childMarkdownRemark.frontmatter.performer2}
                performer3={node.childMarkdownRemark.frontmatter.performer3}
                local_video_URL={
                  node.childMarkdownRemark.frontmatter.attachment.publicURL
                }
                date={node.childMarkdownRemark.frontmatter.date}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Video
