import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const Biography = props => {
  return (
    <>
      <div id="biography" className="[ section wrapper ]">
        <div>
          <div id="bio-content" className="[ flow ]">
            <div className=" [ accent-line ] ">
              <h2>Biography</h2>
            </div>
            <p>
              Soprano Alexandra Whitfield, praised for her “touching and
              poignant” sound (Boston Musical Intelligencer), is an active
              performer in oratorio, opera, concert
              and new music. Her most recent engagements include Bach’s
              Christmas Oratorio with the New Bedford Choral Society, The Queen
              in Solomon with Cantata Singers, Pamina in The Magic Flute with
              The Nahant Music Festival, Handel’s Messiah with Masterworks
              Chorale and the Fauré Requiem with The Litchfield County Choral
              Union.
            </p>

            <p>
              Alexandra has performed as a soloist in concert with The Boston
              Ballet, The Lexington Symphony, The Newton Community Chorus, The
              Cantata Singers Chamber Ensemble and The Berwick Community Chorus.
              Her opera roles include Pamina (The Magic Flute), Josephine (HMS
              Pinafore), Zerlina (La Serva Padrona) and Belinda (Dido and
              Aeneas), working with Opera New Hampshire, Piccola Opera and
              Longwood Opera.
            </p>

            <p>
              Dedicated to chamber music and promoting new and emerging pieces,
              Alexandra has collaborated with local composers, performing
              commissioned works and crafting recitals. Most recently, she
              produced a world premiere program of new compositions and spoken
              word, "
              <a
                href="https://mlkmemorialconcert.wordpress.com/"
                className="[ link-hover color-secondary ]"
              >
                In His Own Words
              </a>
              ,” in honor of Dr. Martin Luther King’s 50th anniversary. She is a
              founding member of the chamber group{" "}
              <a
                href="https://tetratrio.wordpress.com/"
                className="[ link-hover color-secondary ]"
              >
                Tetra Trio
              </a>
              , which continues to develop creative recital programs.
            </p>

            <p>
              Alexandra received her Masters of Music in Vocal Performance from
              the Longy School of Music, and was the only vocalist to be awarded
              the Roman Totenberg Award for “stellar academic and artistic
              achievement and deep commitment to the art of music”. She placed
              in second for NEMPAC’s vocal competition, was a prize-winner in
              the Massachusetts NATS competition and a semi-finalist in the
              American Prize Competition and Rochester Classical Idol. Alexandra
              currently sings as a member of Ars Nova, Cantata Singers and Et Al.
            </p>
            <div className="back-to-somewhere">
              <Link to="/" className="[ link-hover ]">
                Back to home
              </Link>
            </div>
            <div className="img [ radius ]">
              <Img fluid={props.picture} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Biography
